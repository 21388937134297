import firebase from "firebase/app";
import "firebase/auth";
import { UAParser } from "ua-parser-js";
import imageCompression from "browser-image-compression";

export default class Common {
  static UserURL = "";
  static ALGORIA_APP_ID = process.env.VUE_APP_ALGORIA_APP_ID;
  static ALGORIA_API_KEY = process.env.VUE_APP_ALGORIA_API_KEY;
  static getUserId() {
    return (firebase.auth().currentUser || { uid: "" }).uid;
  }

  static createUserIconURL(uid: string, fileExist: boolean) {
    if (fileExist) {
      return (
        `https://firebasestorage.googleapis.com/v0/b/${process.env.VUE_APP_FIREBASE_PROJECT_ID}.appspot.com/o/` +
        encodeURIComponent(`users/${uid}/image`) +
        "?alt=media"
      );
    } else {
      return "";
    }
  }

  static createURL(path: string) {
    return (
      `https://firebasestorage.googleapis.com/v0/b/${process.env.VUE_APP_FIREBASE_PROJECT_ID}.appspot.com/o/` +
      encodeURIComponent(path) +
      "?alt=media"
    );
  }

  static createIntegerRateIcon(rate: number) {
    let rateIcons: any[] = [];
    if (!rate) {
      return rateIcons;
    }

    for (let i = 0; i < rate; i++) {
      rateIcons.push({ iconGroup: "fas", icon: "star" });
    }

    for (let i = rateIcons.length; i < 5; i++) {
      rateIcons.push({ iconGroup: "far", icon: "star" });
    }
    return rateIcons;
  }

  static imageExist(url: string) {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.onload = () => {
        resolve();
      };
      image.onerror = () => {
        reject();
      };
      image.src = url;
      return url;
    });
  }

  static getUserInfo() {
    return new Promise((resolve, reject) => {
      let uid = Common.getUserId();
      if (!uid) {
        resolve();
        return;
      }

      let user = sessionStorage.getItem("user");
      if (user) {
        resolve(JSON.parse(user));
        return;
      }

      firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .get()
        .then(result => {
          if (result.exists) {
            let userInfo = result.data()!;
            userInfo.uid = uid;
            userInfo.group = {};
            if (userInfo.gid) {
              firebase
                .firestore()
                .collection("groups")
                .doc(userInfo.gid)
                .get()
                .then(result => {
                  if (result.exists) {
                    userInfo.group = result.data()!;
                  }
                });
            }
            sessionStorage.setItem("user", JSON.stringify(userInfo));
            resolve(userInfo);
          } else {
            resolve();
          }
        })
        .catch(() => {
          reject();
        });
    });
  }

  static getGroups() {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("groups")
        .get()
        .then(result => {
          let groups: any[] = [];
          if (!result.empty) {
            result.docs.forEach(group => {
              let obj = group.data();
              obj.id = group.id;
              groups.push(obj);
            });
          }
          resolve(groups);
        })
        .catch(() => {
          reject();
        });
    });
  }

  static getCategory() {
    return new Promise((resolve, reject) => {
      let category = sessionStorage.getItem("category");
      if (category) {
        resolve(JSON.parse(category));
        return;
      }

      firebase
        .firestore()
        .collection("categories")
        .orderBy("value")
        .get()
        .then(result => {
          let categories: any[] = [];
          if (!result.empty) {
            result.docs.forEach(category => {
              let obj = category.data();
              obj.id = category.id;
              categories.push(obj);
            });
            sessionStorage.setItem("category", JSON.stringify(categories));
          }
          resolve(categories);
        })
        .catch(() => {
          reject();
        });
    });
  }

  static addTextCursor(textarea: any, text: string, selectionStart?: number) {
    let sentence = textarea.value;
    let len = sentence.length;
    let pos = selectionStart ? selectionStart : textarea.selectionStart;
    var before = sentence.substr(0, pos);
    var word = text;
    var after = sentence.substr(pos, len);
    sentence = before + word + after;
    return sentence;
  }

  static isMobile() {
    return (
      new UAParser().getDevice().type === "mobile" ||
      new UAParser().getDevice().type === "tablet"
    );
  }

  static isChrome() {
    return new UAParser().getBrowser().name == "Chrome";
  }

  static useWebRTC() {
    let browser = new UAParser().getBrowser().name;
    return (
      browser == "Chrome" ||
      browser == "Safari" ||
      browser == "Firefox" ||
      browser == "Edge" ||
      Common.isMobile()
    );
  }

  static createUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  static createViewSkill(skills: any) {
    let skillArray: string[] = [];
    for (let skill of skills) {
      skillArray.push(skill);
      if (skillArray.length >= 5) {
        break;
      }
    }
    return skillArray;
  }

  static getBlob(url: string) {
    return new Promise(resolve => {
      var request = new XMLHttpRequest();
      request.open("GET", url, true);
      request.responseType = "blob";
      request.onload = () => {
        let blob = request.response;
        blob.lastModifiedDate = new Date();
        blob.url = url;
        resolve(blob);
      };
      request.send();
    });
  }

  static async getCompressImageFileAsync(file: File, maxWidthOrHeight = 1920) {
    let options = {
      maxSizeMB: 1,
      maxWidthOrHeight: maxWidthOrHeight
    };
    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.error("getCompressImageFileAsync is error", error);
      throw error;
    }
  }
}
