













































































































































import { Component, Vue } from "vue-property-decorator";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import Common from "./common/Common";
import moment from "moment";
import Footer from "@/Footer.vue";
@Component({
  components: {
    Footer
  }
})
export default class App extends Vue {
  isLogin = false;
  isLoading = true;
  isMainLoading = false;
  viewMenu = false;
  userInfo: any = {};
  imageUrl: any = "";
  unsubscribe: any;
  rooms: any = {};
  chatSum = 0;
  stateChange: any = null;
  pref: any = [];
  beforeMount() {
    this.stateChange ? this.stateChange() : null;
    this.stateChange = firebase.auth().onAuthStateChanged(user => {
      this.checkLogin();
      this.unsubscribe ? this.unsubscribe() : null;

      if (user) {
        let uid = Common.getUserId();
        let oneMonthAgo = moment(new Date())
          .add(-30, "days")
          .toDate();
        this.unsubscribe = firebase
          .firestore()
          .collectionGroup("rooms")
          .where("uids", "array-contains", uid)
          .where("chatCreateTime", ">", oneMonthAgo)
          .onSnapshot(snapshot => {
            snapshot.docChanges().forEach(change => {
              let id = change.doc.id;
              if (
                this.$router.currentRoute.name == "chat" &&
                this.$router.currentRoute.params.chatid == change.doc.id
              ) {
                this.rooms[id] = 0;
                this.chatSum = 0;
                for (let key in this.rooms) {
                  this.chatSum += this.rooms[key];
                }
              } else if (
                change.type === "added" ||
                change.type === "modified"
              ) {
                let data = change.doc.data()!;
                if (data.chatUid != uid) {
                  this.rooms[id] = data.chatNum;
                } else {
                  this.rooms[id] = 0;
                }
                if (data.clinic == uid && data.clinicChatNum) {
                  this.rooms[id] += data.clinicChatNum;
                } else if (data.patient == uid && data.studentChatNum) {
                  this.rooms[id] += data.studentChatNum;
                }
                this.chatSum = 0;
                for (let key in this.rooms) {
                  this.chatSum += this.rooms[key];
                }
              } else if (change.type === "removed") {
                this.rooms[change.doc.id] = 0;

                this.chatSum = 0;
                for (let key in this.rooms) {
                  this.chatSum += this.rooms[key];
                }
              }
              if (this.chatSum > 0) {
                document.title = `オンライン相談 (${this.chatSum})`;
              } else {
                document.title = `オンライン相談`;
              }
            });
          });

        Common.getUserInfo().then((userInfo: any) => {
          this.userInfo = userInfo;
          this.isLoading = false;
          this.imageUrl = Common.createUserIconURL(
            this.userInfo.uid,
            this.userInfo.fileExist
          );
        });
      } else {
        this.userInfo = {};
        this.isLoading = false;
      }
    });
  }

  mounted() {}

  setCanSearchSubmit() {}

  checkLogin() {
    this.isLogin = Common.getUserId().length != 0;
  }

  hideDropdown() {
    (<any>this.$refs.mainDropdown).hide(true);
  }

  hideSubDropdown() {
    (<any>this.$refs.subDropdown).hide(true);
  }

  logout() {
    firebase.auth().signOut();
    this.$router.push("/provider/login");
  }

  beforeDestroy() {
    this.unsubscribe ? this.unsubscribe() : null;
  }
}
