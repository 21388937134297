





















import { Component, Vue } from "vue-property-decorator";
import Common from "./common/Common";
import firebase from "firebase/app";
import moment from "moment";

@Component
export default class Footer extends Vue {
  isLogin = false;
  isLoading = true;
  categories = [];
  chatSum = 0;
  viewQsCategory = true;
  viewCsCategory = true;
  viewTcCategory = true;
  userInfo: any = {};
  stateChange: any = null;
  unsubscribe: any;
  rooms: any = {};
  iconUrl = "";

  beforeMount() {
    this.isLogin = Common.getUserId().length != 0;
    Common.getUserInfo().then((userInfo: any) => {
      this.userInfo = userInfo;
    });
    this.stateChange ? this.stateChange() : null;
    this.stateChange = firebase.auth().onAuthStateChanged(user => {
      this.checkLogin();
      this.unsubscribe ? this.unsubscribe() : null;

      if (user) {
        let uid = Common.getUserId();
        let oneMonthAgo = moment(new Date())
          .add(-30, "days")
          .toDate();
        this.unsubscribe = firebase
          .firestore()
          .collectionGroup("rooms")
          .where("uids", "array-contains", uid)
          .where("chatCreateTime", ">", oneMonthAgo)
          .onSnapshot(snapshot => {
            snapshot.docChanges().forEach(change => {
              let id = change.doc.id;
              if (
                this.$router.currentRoute.name == "chat" &&
                this.$router.currentRoute.params.chatid == change.doc.id
              ) {
                this.rooms[id] = 0;
                this.chatSum = 0;
                for (let key in this.rooms) {
                  this.chatSum += this.rooms[key];
                }
              } else if (
                change.type === "added" ||
                change.type === "modified"
              ) {
                let data = change.doc.data()!;
                if (data.chatUid != uid) {
                  this.rooms[id] = data.chatNum;
                } else {
                  this.rooms[id] = 0;
                }
                if (data.clinic == uid && data.clinicChatNum) {
                  this.rooms[id] += data.clinicChatNum;
                } else if (data.patient == uid && data.studentChatNum) {
                  this.rooms[id] += data.studentChatNum;
                }
                this.chatSum = 0;
                for (let key in this.rooms) {
                  this.chatSum += this.rooms[key];
                }
              } else if (change.type === "removed") {
                this.rooms[change.doc.id] = 0;

                this.chatSum = 0;
                for (let key in this.rooms) {
                  this.chatSum += this.rooms[key];
                }
              }
              if (this.chatSum > 0) {
                document.title = `オンライン相談 (${this.chatSum})`;
              } else {
                document.title = `オンライン相談`;
              }
            });
          });

        Common.getUserInfo().then((userInfo: any) => {
          this.userInfo = userInfo;
          if (userInfo) {
            this.iconUrl = Common.createUserIconURL(
              userInfo.uid,
              userInfo.fileExist
            );
          }
          this.isLoading = false;
        });
      } else {
        this.userInfo = {};
        this.isLoading = false;
      }
    });
  }
  mounted() {
    Common.getCategory().then((categories: any) => {
      this.categories = categories;
    });
  }

  checkLogin() {
    this.isLogin = Common.getUserId().length != 0;
  }
}
