import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

var originalPush: any = Router.prototype.push;
var originalGo: any = Router.prototype.go;
Router.prototype.push = function push(location: any) {
  return originalPush.call(this, location).catch((err: any) => {
    if (err && err.name == "NavigationDuplicated") {
      originalGo.call(this, location);
    }
  });
};
var originalReplace: any = Router.prototype.replace;
Router.prototype.replace = function replace(location: any) {
  return originalReplace.call(this, location).catch((err: any) => {});
};

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/provider-setting",
      name: "providerSetting",
      component: () => import("./views/user/SettingClinic.vue"),
      meta: { requiresAuth: true, breadcrumbs: "サービス提供ユーザー設定" }
    },
    {
      path: "/unsupport",
      name: "unsupport",
      component: () => import("./views/warn/UnsupportBrowser.vue"),
      meta: { breadcrumbs: "未対応のブラウザです" }
    },
    {
      path: "/onayami/create",
      name: "onayamiCreate",
      component: () => import("./views/onayami/OnayamiCreate.vue"),
      meta: { requiresAuth: true, breadcrumbs: "ルーム作成" }
    },
    {
      path: "/onayami/create/:id",
      name: "onayamiUpdate",
      component: () => import("./views/onayami/OnayamiCreate.vue"),
      meta: { requiresAuth: true, breadcrumbs: "ルーム更新更新" }
    },
    {
      path: "/onayami/list",
      name: "onayamiList",
      component: () => import("./views/onayami/OnayamiList.vue"),
      meta: { breadcrumbs: "ルーム一覧" }
    },
    {
      path: "/onayami/:id",
      name: "onayamiDetail",
      component: () => import("./views/onayami/OnayamiDetail.vue"),
      meta: { breadcrumbs: "ルーム詳細" }
    },
    {
      path: "/chatlist",
      name: "chatlist",
      component: () => import("./views/chat/ChatList.vue"),
      meta: { requiresAuth: true, breadcrumbs: "相談一覧" }
    },
    {
      path: "/onayamis/:id/chat/:chatid",
      name: "chat",
      component: () => import("./views/chat/Chat.vue"),
      meta: { requiresAuth: true, breadcrumbs: "チャット" }
    },
    {
      path: "/onayamis/chatbot",
      name: "chatBot",
      component: () => import("./views/chat/ChatBot.vue"),
      meta: { requiresAuth: true, breadcrumbs: "ボット" }
    },
    {
      path: "/login",
      name: "login",
      component: () => import("./views/Login.vue"),
      meta: { breadcrumbs: "ログイン" }
    },
    {
      path: "/provider/login",
      name: "provider-login",
      component: () => import("./views/Login.vue"),
      meta: { breadcrumbs: "サービス提供ユーザーログイン" }
    },
    {
      path: "*",
      name: "404",
      component: () => import("./views/warn/Page404.vue"),
      meta: { breadcrumbs: "404" }
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(savedPosition);
        }, 500);
      });
    } else {
      return { x: 0, y: 0 };
    }
  }
});
