var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],staticClass:"d-none d-lg-block mt-4 mt-md-0 pb-5",staticStyle:{"max-width":"250px","margin-left":"auto","margin-right":"1rem"}},[_c('div',{staticClass:"mr-2"},[_c('div',{staticClass:"bg-omecon-blue",class:{
        'omecon-sidebar-chat': _vm.$route.name == 'chat',
        'omecon-sidebar-main': _vm.$route.name != 'chat'
      },staticStyle:{"font-size":"14px !important","font-weight":"bold !important"}},[(!_vm.isLogin)?_c('router-link',{staticClass:"ml-2 omecon-sidebar-link",class:{ 'current-path': _vm.$route.path === '/' },attrs:{"to":'/'}},[_c('div',{staticClass:"icon home d-inline-block text-center",staticStyle:{"width":"2rem"}}),_vm._v(" トップページ ")]):_vm._e(),(_vm.isLogin)?_c('router-link',{staticClass:"ml-2 mt-3 d-flex align-items-center omecon-sidebar-link",class:{ 'current-path': _vm.$route.path === '/chatlist' },attrs:{"to":'/chatlist'}},[_c('div',{staticClass:"icon chat d-inline-block text-center",staticStyle:{"width":"2rem"}}),_vm._v(" 相談一覧 "),(_vm.chatSum != 0)?_c('div',{staticClass:"d-inline-block ml-3"},[_c('div',{staticClass:"\n              badge\n              text-white\n              d-flex\n              align-items-center\n              justify-content-center\n            ",staticStyle:{"background-color":"#ff4a4a","width":"1.35rem","height":"1.35rem"}},[_vm._v(" "+_vm._s(_vm.chatSum > 9 ? "9+" : _vm.chatSum)+" ")])]):_vm._e()]):_vm._e(),(
          _vm.isLogin && _vm.userInfo.role == 'clinic' && _vm.userInfo.isOperator == true
        )?_c('hr',{staticStyle:{"margin":"2rem 0"}}):_vm._e(),(
          _vm.isLogin && _vm.userInfo.role == 'clinic' && _vm.userInfo.isOperator == true
        )?_c('router-link',{staticClass:"ml-2 d-block omecon-sidebar-link",class:{ 'current-path': _vm.$route.path === '/onayami/create' },attrs:{"to":'/onayami/create'}},[_c('div',{staticClass:"d-inline-block text-center",staticStyle:{"width":"2rem"}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":['far', 'edit'],"size":"lg"}})],1),_vm._v(" ルームを作成 ")]):_vm._e(),(
          _vm.isLogin && _vm.userInfo.role == 'clinic' && _vm.userInfo.isOperator == true
        )?_c('router-link',{staticClass:"ml-2 mt-3 d-block omecon-sidebar-link",class:{ 'current-path': _vm.$route.path === '/onayami/list' },attrs:{"to":'/onayami/list'}},[_c('div',{staticClass:"d-inline-block text-center",staticStyle:{"width":"2rem"}},[_c('font-awesome-icon',{staticClass:"mr-2",staticStyle:{"font-size":"1.2rem"},attrs:{"icon":['fas', 'chalkboard-teacher'],"size":"lg"}})],1),_vm._v(" ルームを確認 ")]):_vm._e(),_c('hr',{staticStyle:{"margin":"2rem 0"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }